try {
    require("datatables.net");
    require("datatables.net-bs5");
    // require("datatables.net-autofill");
    // require("datatables.net-autofill-bs5");
    // require("datatables.net-buttons");
    // require("datatables.net-buttons-bs5");
    // require("datatables.net-colreorder");
    // require("datatables.net-colreorder-bs5");
    // require("datatables.net-fixedcolumns");
    // require("datatables.net-fixedcolumns-bs5");
    // require("datatables.net-fixedheader");
    // require("datatables.net-fixedheader-bs5");
    // require("datatables.net-keytable");
    // require("datatables.net-keytable-bs5");
    // require("datatables.net-responsive");
    // require("datatables.net-responsive-bs5");
    // require("datatables.net-rowreorder");
    // require("datatables.net-rowreorder-bs5");
    // require("datatables.net-scroller");
    // require("datatables.net-scroller-bs5");
    // require("datatables.net-select");
    // require("datatables.net-select-bs5");
    // require("datatables.net-select-bs5");
    // require("jquery-datatables-checkboxes");
} catch (e) {}
