/*
$("#listaModalEmitentes .emitente").on("click", function (ev) {
    ev.preventDefault();
    var url = $(this).attr("data-action");

    // console.log(url);

    $.ajax({
        url: url,
        method: "POST",
        dataType: "JSON",
        contentType: false,
        cache: false,
        processData: false,
        success: function (response) {
            // console.log(response);
            var loc = window.location;
            // console.log(loc);
            window.location = loc.protocol + "//" + loc.host;
        },
        error: function (response) {
            // console.log(response.error);
        },
    });
});
*/

$("#invoiceItens .print").on("click", function (ev) {
    ev.preventDefault();
    var url = $(this).attr("data-action");

    // console.log(url);

    $.ajax({
        url: url,
        method: "POST",
        dataType: "JSON",
        contentType: false,
        cache: false,
        processData: false,
        success: function (response) {
            // console.log(response.success);
            // window.location.href = "invoice";
        },
        error: function (response) {
            // console.log(response.error);
        },
    });
});
