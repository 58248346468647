try {
    window.$ = window.jQuery = require("jquery");

    // require("jquery-ui");
    require('jquery-mask-plugin');
    // require("jquery-ui/ui/widgets/sortable");
    // require("bootstrap-datepicker");

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    })
} catch (e) {}
